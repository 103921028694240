.flex-grow {
  flex-grow: 1;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

[type="checkbox"] {
  vertical-align: middle;
}

.custom-text-warning {
  color: rgb(255, 193, 7);
}

.custom-text-danger {
  color: rgb(220, 53, 69);
}

.custom-text-success {
  color: rgb(40, 167, 69);
}

.button-text-only {
  background-color: transparent;
  border: none;
}

.date-range-picker-wrapper {
  position: absolute !important;
}

@media (max-width: 600px) {
  .date-range-picker-wrapper > div > div > div:nth-child(3) {
    display: none;
  }

  .date-range-picker-wrapper > div > div > div > hr ~ div {
    display: flex;
    flex-direction: column;
  }
}

.font-bold {
  font-weight: 700 !important;
}

.react-bootstrap-table tbody > tr {
  cursor: pointer;
}

.react-bootstrap-table tbody > tr:hover {
  background-color: rgb(240, 240, 240);
}

.gap-20px {
  row-gap: 20px;
  column-gap: 20px;
}

.card.card-custom > .card-header {
  padding-left: 12.5px;
  padding-right: 12.5px;
  align-items: center !important;
}

._card-toolbar-start {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  padding: 12px 20px;
  justify-content: flex-start;
}

._card-toolbar-start > * {
  margin-right: 16px;
}

._cropper-preview {
  background: #d1d1d1;
  border-radius: 5px;
}

._cropper-preview-container {
  position: relative;
  display: inline-block;
}

._cropper-preview-edit-btn,
._cropper-preview-delete-btn {
  position: absolute;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 9px 16px 0px rgb(24 28 50 / 25%);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

._cropper-preview-edit-btn {
  right: 0;
  top: 0;
  transform: translate3d(50%, -50%, 0);
}

._cropper-preview-delete-btn {
  right: 0;
  bottom: 0;
  transform: translate3d(50%, 50%, 0);
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}
